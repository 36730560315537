.footer {
  background-color: #1c1d21;
  color: white;
  padding: 40px 20px;
}

.contact-info-container,
.contact-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* .contact-title {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
} */

.contact-info {
  /* border: 1px solid white; */
  border-radius: 1.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.4em;
  background-color: hsl(20, 43.61%, 55.49%);
}

.contact-info a {
  color: black;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.3rem;
}

.socials {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.socials p {
  color: #827f7f;
}

.socials-list {
  display: flex;
  gap: 20px;
}

.socials-list li {
  list-style: none;
}

.socials-list li a {
  text-decoration: none;
  color: white;
}

.email-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-email {
  color: white;
}

.footer-cta {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .contact-info {
    width: 200px;
  }

  .footer-cta {
    text-align: start;
  }
  .footer {
    padding: 30px 100px;
  }
}

@media only screen and (min-width: 1024px) {
  .footer {
    padding: 30px 200px;
  }
}
