.about-headshot {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.about-container {
  padding: 0 20px;
}

.about-paragraphs p {
  line-height: 1.8em;
  margin: 1em 0;
}

.about-container-title {
  margin-bottom: 15px;
}

/* Contact */

.contact-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 40px 0px;
}

.contact-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
  padding: 0 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.name-container {
  display: flex;
  gap: 10px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-btn {
  padding: 10px 20px;
  outline: none;
  border: none;
  background-color: #1c1d21;
  color: white;
}

.input-container > input,
.input-container > textarea {
  padding: 10px;
}

.contact-btn-container {
  align-self: center;
}

.about-introduction {
  display: none;
}

@media only screen and (min-width: 768px) {
  .about-container {
    display: flex;
    padding: 30px 100px;
  }

  .about-container-title {
    display: none;
  }

  .about-introduction {
    display: block;
  }

  .about-paragraphs {
    padding: 0px 40px;
  }

  .about-headshot {
    height: 400px;
  }
}

@media only screen and (min-width: 1024px) {
  .about-container {
    padding: 30px 200px;
  }
}
