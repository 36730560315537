.hero-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.hero-banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 20%;
}

.navigation-container {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.section-text {
  position: absolute;
  bottom: 50px;
  left: 40px;
  color: white;

  display: flex;
  flex-direction: column;
  gap: 30px;
}

.section-text > span {
  font-size: 1.5rem;
}

.section-titles {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 1.3rem;
}
