:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: black;
  --textColor: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  color: var(--textColor);
}

.text-logo a {
  /* color: white; */
  text-decoration: none;
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  /* color: var(--textColor); */
  display: none;
  opacity: 0;
}

header div,
nav {
  display: flex;
  align-items: center;
}

.homeTextLogo a,
.homeNavBtn {
  color: white;
}

.otherTextLogo a,
.otherNavBtn {
  color: black;
}

.otherNav a {
  color: black;
}

.otherHeader {
  border-bottom: 1px solid gray;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    display: block;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #999d9e;
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: white;
    font-size: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  /* Adjust the max-width as needed for your specific breakpoint */
  .otherNav a {
    color: white;
  }
}
