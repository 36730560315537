.info-wrapper {
  padding: 10px;
}
.info-container {
  background-color: #e9eaeb;
  padding: 15px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-title {
  color: #424446;
}

.info-item {
  display: flex;
  gap: 10px;
}

.info {
  color: black;
}

.project-overview-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.visit-website {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0e0e12;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  width: 180px;
}

.project-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.project-overview-title {
  text-align: center;
}

.project-overview-description {
  padding: 0 10px;
  text-align: center;
}

.project-overview-logo {
  width: 60%;
  height: auto;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1024px) {
  .info-wrapper {
    padding: 20px 100px;
  }

  .project-overview {
    width: 800px;
  }
}
