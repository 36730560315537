.portfolio-img {
  display: flex;
  width: 100%;
  min-height: 300px;
}

.portfolio-img > img {
  max-width: 100%;
  height: auto;
}

.portfolio-wrapper {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
}

.roles {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
}

.project-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}

.project-title {
  font-size: 1.3rem;
}

.portfolio-header {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.portfolio-header a {
  text-decoration: none;
  color: black;
}

.portfolio-container {
  margin-bottom: 30px;
}

.linkStyle {
  color: black;
  text-decoration: none;
}

.project-redirect {
  text-decoration: underline;
}

.portfolio-description {
  margin-top: -20px;
  padding: 10px;
  text-align: center;
}

.portfolio-description-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 1.1em;
}

@media only screen and (min-width: 768px) {
  .portfolio-img {
    display: none;
  }

  .portfolio-wrapper {
    padding: 30px 100px;
  }

  .portfolio-description-container {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .portfolio-wrapper {
    padding: 30px 200px;
  }
}
